import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestserviceService {

  constructor(private http: HttpClient) { }
  getRequestforSP(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'GetrequestList.do', data, {headers : reqHeader});
  }
  getRequestDetailsById(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'getsprequestbyid.do', data, {headers : reqHeader});
  }
  getSpServices(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListServiceseBySP.do', {headers : reqHeader});
  }
  getSpSpares(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListSpareBySP.do', {headers : reqHeader});
  }
  gettecniciandetails(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.get(environment.appUrl+'Listtechnician.do', {headers : reqHeader});

  }
  SaveSpServices(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'AddServiceBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  SaveSpSpares(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'AddSpareBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  SaveSpTechnician(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'addtechnician.do', data, {headers : reqHeader,responseType: 'text'});
  }
  saveQuote(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'createnewQuote.do', data, {headers : reqHeader,responseType: 'text'});
  }
  getEstimates(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'GetQuoteListBySP.do',{headers : reqHeader});
  }
  getEstimatesById(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditQuoteBySP.do',data,{headers : reqHeader});
  }
  updateQuote(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateQuoteBySP.do',data,{headers : reqHeader,responseType: 'text'});
  }
  createMotorist(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'createmotoristbysp.do',data,{headers : reqHeader,responseType: 'text'});
  }

  updateMotorist(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'updatemotoristbysp.do',data,{headers : reqHeader,responseType: 'text'})
  }

  deletemotorist(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'deletetemymotorist.do', data, {headers : reqHeader,responseType: 'text'});
  }


  getCustomerById(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditMotoristMysp.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getMotoristList(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'getSpmmotoristList.do',{headers : reqHeader});
  }
  approveQuote(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'approveworckcard.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getMotoristVehicleList(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'listmymotoristvehicle.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getWorkCardList(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'GetWorkcardListBySP.do',{headers : reqHeader});
  }
  getworkcardbyid(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'GetWorkcardById.do',data,{headers : reqHeader});
  }
  updateWorkcard(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateWorkcard.do',data,{headers : reqHeader,responseType: 'text'});
  }
  createInvoice(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'createinvoice.do',data,{headers : reqHeader,responseType: 'text'});
  }
  updateinvoice(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'Updateinvoice.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getInvoiceList(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'GetInvoiceListBySP.do',{headers : reqHeader});
  }
  getInvoicebyid(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'GetInvoiceById.do',data,{headers : reqHeader});
  }
  saveworkcardimage(data:any){
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'uploadworkcardimage.do',data,{headers : reqHeader,responseType: 'text'});
  }
  getUserdetails(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'getuserdetails.do',{headers : reqHeader});
  }
  updateuserprofile(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateUserProfile.do',data,{headers : reqHeader,responseType: 'text'});
  }
  saveprofilepic(data:any){
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'uploadprofilepic.do',data,{headers : reqHeader,responseType: 'text'});
  }
  updatespdetils(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateSpdetails.do',data,{headers : reqHeader,responseType: 'text'});
  }
  savesplogo(data:any){
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'uploadgaragelogo.do',data,{headers : reqHeader,responseType: 'text'});
  }
  spalistbysp(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListMySPAforSP.do',{headers : reqHeader});
  }
  addmyspa(data:any){
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'AddSPAasMyspaBySP.do',data,{headers : reqHeader,responseType: 'text'});
  }
  listmyspas(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'ListMySPAdetailsforSP.do',{headers : reqHeader});
  }
  newassistancerequest(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'createSPAservicerequest.do',data,{headers : reqHeader,responseType: 'text'});
  }
  EditSpService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditServiceBySP.do',data, {headers : reqHeader});
  }
  UpdateSpService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateServiceBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  DeleteSpService(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'DeleteServiceBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  EditSpSpare(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditSpareBySP.do',data, {headers : reqHeader});
  }
  UpdateSpSpare(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'UpdateSpareBySP.do', data, {headers : reqHeader,responseType: 'text'});
  }
  DeleteSpSpare(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'DeleteSpareBySP.do', data, {headers : reqHeader,responseType: 'text'});
  }
  EditSpTechnician(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'edittechnician.do',data, {headers : reqHeader});
  }
  UpdateSpTechnician(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'updatetechnician.do', data, {headers : reqHeader,responseType: 'text'});
  }
  DeleteSpTechnician(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'deletetechnician.do', data, {headers : reqHeader,responseType: 'text'});
  }
  getspaworks(){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.get(environment.appUrl+'listworkcardsbysp.do',{headers : reqHeader});
  }
  getSpaWorkbyid(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'EditjobcardBySPA.do', data, {headers : reqHeader});
  }
  savemessage(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'savechatmessage.do', data, {headers : reqHeader,responseType: 'text'});
  }
  loadmessage(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'listchatmessage.do', data, {headers : reqHeader});
  }
  acceptrsa(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'updatersarequest.do', data, {headers : reqHeader,responseType: 'text'});
  }
  getrsalistsp(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'GetrequestRSAList.do', data, {headers : reqHeader});
  }

  getVehicleList(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'getvehiclelist.do',data, {headers : reqHeader});
  }
  getpaymentsDetails(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'ListPaymentsBySP.do',data, {headers : reqHeader});
  }

  savepaymentsDetails(data: any) {
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'payments.do', data, {headers : reqHeader,responseType: 'text'});

  }

  Deletepayment(data: any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+'DeletePaymentBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }
  Uploadchatimage(data: any){
    var reqHeader = new HttpHeaders({'Authorization':"Bearer "+localStorage.getItem("token")});
   
    return this.http.post(environment.appUrl+'uploadchatimageBySP.do', data, {headers : reqHeader,responseType: 'text'});

  }

  createestimate(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+"approveworckcardbymoto.do",data,{headers : reqHeader,responseType: 'text'})

  }

  estimatesavefromex(data:any){
    var reqHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization':"Bearer "+localStorage.getItem("token")});
    return this.http.post(environment.appUrl+"estimatesavefromex.do",data,{headers : reqHeader,responseType: 'text'})

  }


}
